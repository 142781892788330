import React from 'react'
import { connect } from 'react-redux'
import {
    logError
} from '../UserMessaging'
import { saveShippingAccountCarrierAsync } from './shippingMethodActions'
import { translateResourceString } from '../../../util/translationUtility';

class UseMyAccountComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            shippingAccountNumber: props.shippingAccountNumber,
            selectedCarrier: props.selectedCarrier,
            isNumberChanged: false,
            showRequiredError: false
        }
    }

    handleInputChange(e){
        if(e.target.value !== this.props.AccountNumber){
            this.setState({isNumberChanged: true, shippingAccountNumber: e.target.value});
        }
        else{
            this.setState({isNumberChanged: false, shippingAccountNumber: e.target.value});
        }
    }

    handleDropdownChange(e){
        if(e.target.value !== this.props.SelectedCarrier){
            this.setState({isNumberChanged: true, selectedCarrier: e.target.value});
        }
        else{
            this.setState({isNumberChanged: false, selectedCarrier: e.target.value});
        }
    }

    saveShippingAccountCarrier() {
        if(this.state.shippingAccountNumber && this.state.shippingAccountNumber !== ''){
            this.props.onUpdateShippingAccount(this.state.shippingAccountNumber, this.state.selectedCarrier);
            this.setState({showRequiredError: false, isNumberChanged: false});
        }
        else {
            this.setState({showRequiredError: true});
        }
    }

    render() {
        const carrierOptions = this.props.carriers.map((carrier) =>
            <option key={carrier.Carrier.Code} value={carrier.Carrier.Code}>{carrier.Carrier.Value}</option>
      );
        let content;
        content = (
            <div id="useMyAccountShippingMethod" className="row" style={{ paddingBottom: 10 }} >
                <div className="col-4">
                    <label htmlFor='shippingAccountNumber'>
                        {translateResourceString("/Checkout/ShippingMethod/UseMyAccountShipping/ShippingCarrier")}
                    </label>
                </div>
                <div className="col-8">
                    <label htmlFor='shippingAccountNumber'>
                        {translateResourceString("/Checkout/ShippingMethod/UseMyAccountShipping/AccountNumber")}
                    </label>
                </div>
                <div className='col-4'>
                    <select
                        value={this.state.selectedCarrier}
                        onChange={(e) =>  this.handleDropdownChange(e)}
                        required
                        className="form-control">
                        {carrierOptions}
                    </select>
                </div>
                <div className="col-8">
                    <input
                        className="form-control"
                        name='shippingAccountNumber'
                        id='shippingAccountNumber'
                        type='text'
                        placeholder={translateResourceString("/Checkout/ShippingMethod/UseMyAccountShipping/AccountNumber")}
                        onKeyPress={e => e.key === "Enter" && this.saveShippingAccountCarrier()}
                        onChange={(e) => this.handleInputChange(e)}
                        value={this.state.shippingAccountNumber}
                    />
                </div>
                {this.props.useMyOwnAccountInitializing
                    ? (
                        <div style={{ marginTop: 40 }}><span className="fas fa-spinner fa-spin" /></div>
                    )
                    : (
                    <button
                        className={`btn btn-r-red mt-4`}
                        disabled={!this.state.isNumberChanged}
                        onClick={() => {this.saveShippingAccountCarrier()}}
                        tabIndex={0}
                        hidden={this.props.useMyOwnAccountInitializing}
                    >
                        {translateResourceString("/Checkout/ShippingMethod/UseMyAccountShipping/SaveShipping")}
                    </button>
                )
                }
                {this.state.showRequiredError
                ?
                    <span className='invalid-feedback d-block'> {translateResourceString("/Checkout/ShippingMethod/UseMyAccountShipping/NoAccountNumberEntered")}</span>
                    : null
                }
            </div>
        )

        return (
            <div className="useMyAccountShipping" style={{ margin: 17 }}>
                {content}
            </div>
        );
    }

}

const mapUseMyAccountStateToProps = (state) => {
    return {
        shippingAccountNumber: state.entities.useMyAccountShippingSettings.AccountNumber,
        selectedCarrier: state.entities.useMyAccountShippingSettings.SelectedCarrier,
        carriers: state.entities.useMyAccountShippingSettings.Carriers,
        useMyOwnAccountInitializing: state.entities.useMyAccountShippingSettings.isUseMyShippingInitializing
    }
};

const mapUseMyAccountDispatchToProps = (dispatch) => {
    return {
        onUpdateShippingAccount: (shippingAccountNumber, carrier) => {
            dispatch(saveShippingAccountCarrierAsync({shippingAccountNumber: shippingAccountNumber, carrierCode: carrier}));
        },
        logDevError: (text) => dispatch(logError(text))
    }
};

const UseMyAccountShippingComponent = connect(mapUseMyAccountStateToProps, mapUseMyAccountDispatchToProps)(UseMyAccountComponent)

export default UseMyAccountShippingComponent;
