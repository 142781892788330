export const hiddenAnchorStyle = {
    color: 'inherit',
    textDecoration: 'none',
    fontWeight: 'normal',
    padding: 0,
    display: 'flex',
    verticalAlign: 'middle',
    textAlign: 'left',
    justifyContent: 'space-between',
    width: '100%'
};

export const optionListItemStyle = {
    listStyle: 'none',
    lineHeight: 1.4
}

export const pretendAnchorStyle = {
    color: '#c72c2c',
    textDecoration: 'none',
    fontWeight: 600
}

export const flexMiddle = {
  display: 'flex',
  verticalAlign: 'middle'
}

