import React from 'react'
import { connect } from 'react-redux'
import deepEqual from 'deep-equal'
import find from 'lodash/find'

import {
    notifyRefreshingShippingOptions,
    refreshShippingOptionsAsync,
    selectShippingMethod,
    sendShippingMethodToGtmDatalayer
} from './shippingMethodActions'

import CheckoutShipmentPresentation from "./CheckoutShipmentPresentation";
import { isValidGuid } from "../../../util/guidUtility";


function dependenciesChanged(prevProps, props) {
    const addressChanged = prevProps.selectedShippingAddressId !== props.selectedShippingAddressId
        || !deepEqual(prevProps.selectedShippingAddress, props.selectedShippingAddress);
    return addressChanged || !deepEqual(prevProps.cartItems, props.cartItems);
}

class CheckoutShipment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: !!props.selectedShippingOptionId
        }
        this.onSelected = this.onSelected.bind(this)
        this.onClickChange = this.onClickChange.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        if (dependenciesChanged(prevProps, this.props)) {
            this.props.onIsStale(this.props.selectedShippingAddressId);
        }
    }

    componentDidMount() {
        this.props.onIsStale(this.props.selectedShippingAddressId);
    }

    onClickChange(e) {
        e.preventDefault();
        this.setState({ isCollapsed: !this.state.isCollapsed });
    }


    onSelected(id) {
        const shippingMethods = this.props.shippingOptions;
        const shippingMethod = find(shippingMethods, sm => sm.Id === id);
        const name = shippingMethod
            ? shippingMethod.Name
            : "";
        if(shippingMethod.Id !== this.props.accountShippingSettings.UseMyAccountShippingMethodId){
            this.setState({ isCollapsed: true });
        }
        this.props.onSelected(id, name);
    }

    render() {
        return React.createElement(CheckoutShipmentPresentation, {
            shippingOptions: this.props.shippingOptions,
            selectedId: this.props.selectedShippingOptionId,
            isPendingRefresh: this.props.isPendingRefresh,
            canChange: this.props.shippingOptions.length > 1 || !this.props.selectedShippingOptionId,
            isCollapsed: this.state.isCollapsed,
            onSelected: this.onSelected,
            onClickChange: this.onClickChange
        });
    }
}



const mapCheckoutShipmentStateToProps = (state) => {
    return {
        shippingOptions: state.entities.ShippingOptions,
        selectedShippingOptionId: state.entities.SelectedShippingOptionId,
        selectedShippingAddressId: state.entities.SelectedShippingAddressId,
        selectedShippingAddress: find(state.entities.Addresses, a => a.Id === state.entities.SelectedShippingAddressId),
        cartItems: state.entities.CartItems,
        isPendingRefresh: state.entities.isRefreshingShippingOptions,
        accountShippingSettings: state.entities.useMyAccountShippingSettings
    }
}

const mapCheckoutShipmentDispatchToProps = (dispatch) => {
    return {
        onSelected: (id, name) => {
            dispatch(selectShippingMethod({id}));
            dispatch(sendShippingMethodToGtmDatalayer(name));
        },
        onIsStale: (shippingAddressId) => {
            if (isValidGuid(shippingAddressId))
                dispatch(refreshShippingOptionsAsync({ addressId: shippingAddressId }))
        }
    };
}

const CheckoutShipmentContainer = connect(mapCheckoutShipmentStateToProps, mapCheckoutShipmentDispatchToProps)(CheckoutShipment);

export default CheckoutShipmentContainer
