import React from 'react'
import { connect } from 'react-redux'

import {
    notifyError,
    logError
} from '../UserMessaging'
import {
    selectPaymentOption,
    updateBraintreePaymentData,
    updatePurchaseOrderNumber
} from './paymentMethodActionCreators'

import { mapStateToPlaceOrderDetails } from '../PlaceOrder'
import { translateResourceString } from '../../../util/translationUtility';

const braintreeLoadTimeoutDuration = 15000;

const makeDropinConfig = (token, amount, locale, { usePaypal, usePaypalCredit, braintreePaypalFlow, currency }) => {
    function getSupportedLocale(inLocale = "") {
        var supportedLocales =
            [// BBS DDD 2017-09-07 https://braintree.github.io/braintree-web/3.22.2/PayPalCheckout.html#createPayment retrieved 2017-09-07
                "en_US", "da_DK", "de_DE", "en_AU", "en_GB", "es_ES", "fr_CA", "fr_FR", "id_ID", "it_IT", "ja_JP",
                "ko_KR", "nl_NL", "no_NO", "pl_PL", "pt_BR", "pt_PT", "ru_RU", "sv_SE", "th_TH", "zh_CN", "zh_HK", "zh_TW"
            ],
            preferredLocale = inLocale.toLowerCase(),
            i = 0,
            found = false;
        supportedLocales.forEach(function (locale, idx, arr) {
            if (found) return;
            if (preferredLocale === locale.toLowerCase()) {
                found = true;
                i = idx;
            }
        });
        if (found) return supportedLocales[i];
        supportedLocales.forEach(function (locale, idx, arr) {
            if (found) return;
            if (locale.toLowerCase().substring(0, preferredLocale.length) === preferredLocale) {
                found = true;
                i = idx;
            }
        });
        if (i > -1) return supportedLocales[i];
        return "";
    }

    const inLocale1 = typeof window !== 'undefined' &&
        window.navigator &&
        window.navigator.language &&
        window.navigator.language.replace
        ? window.navigator.language.replace('-', '_')
        : '';
    var dropinConfig = {
        authorization: token,
        container: "#braintreeHolder",
        locale: locale ||
            getSupportedLocale(
                inLocale1),
        //vaultManager: true,
        card: {
            vault: {
                allowVaultCardOverride: true
            }
        }
    }
    const paypalConfig = { flow: braintreePaypalFlow, amount, currency };
    if (usePaypal) {
        dropinConfig.paypal = paypalConfig
        if (usePaypalCredit) {
            dropinConfig.paypalCredit = paypalConfig
        }
    }
    return dropinConfig;
}

class BraintreePaymentComponent extends React.Component {
    constructor(props) {
        super(props);
        const { isSelected, BraintreePaymentNonce } = props;
        this.initBraintree = this.initBraintree.bind(this);
        this.onBraintreeLoadTimeout = this.onBraintreeLoadTimeout.bind(this)
        this.onClientTokenReceived = this.onClientTokenReceived.bind(this)
        this.initBraintree = this.initBraintree.bind(this)
        this.componentDidUpdate = this.componentDidUpdate.bind(this)
        this.onClickSuccess = this.onClickSuccess.bind(this)
        this.onDropinCreated = this.onDropinCreated.bind(this)
        this.onStartOver = this.onStartOver.bind(this)
        this.dropin = null

        this.state = {
            btInitializing: false,
            dropinInstance: null,
            paymentMethodAvailable: false,
            isOpen: isSelected,
            btLoaded: false,
            nonce: BraintreePaymentNonce
        }
    }

    componentWillUnmount() {
        this.state.dropinInstance && this.state.dropinInstance.teardown();
        this.setState({ dropinInstance: null, paymentMethodAvailable: false, btLoaded: false })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isSelected) {
            if (!prevState.isOpen && !this.state.isOpen) {
                this.setState({ isOpen: true });
            }
        }
        if (!prevState.btLoaded && !prevState.btInitializing && !this.state.btLoaded && !this.state.btInitializing) {
            this.initBraintree(this.props);
        }
    }

    componentDidMount() {
        this.dropin = require('braintree-web-drop-in')
        if (this.props.isSelected && (!this.state.nonce || this.state.nonce.length < 1)) {
            this.setState({ isOpen: true });
            if (!this.state.btLoaded && !this.state.btInitializing) {
                this.initBraintree(this.props);
            }
        }
    }

    onStartOver(e) {
        e.preventDefault();
        this.props.onStartOver();
        this.state.dropinInstance && this.state.dropinInstance.teardown();
        this.setState({ btInitializing: false, nonce: '', btLoaded: false, paymentMethodAvailable: false });
        this.initBraintree(this.props);
    }

    onPaymentCancel(e) {
        e.preventDefault();
        this.setState({ btInitializing: false, isOpen: false });
        this.props.onPaymentCancel();
    }

    onBraintreeLoadTimeout() {
        if (!this.state.btLoaded) {
            this.props.onBraintreeLoadTimeout();
        }
    }

    onDropinCreated(createErr, dropinInstance) {
        this.setState({ btInitializing: false });
        if (createErr) {
            this.props.onCreateDropinError(
                `${translateResourceString("/Checkout/PaymentInformation/Braintree/ErrorInitializingBraintree")}: ${createErr}. ${translateResourceString("/Checkout/PaymentInformation/Braintree/PleaseReloadOrContactCustomerService")}`,
                createErr);
            return;
        }
        this.setState({
            dropinInstance: dropinInstance,
            btLoaded: true,
            paymentMethodAvailable: dropinInstance.isPaymentMethodRequestable()
        });
        dropinInstance.on('paymentMethodRequestable', () => this.setState({ paymentMethodAvailable: true }));
        dropinInstance.on('noPaymentMethodRequestable', () => this.setState({ paymentMethodAvailable: false }));
    }

    onClientTokenReceived(token, nextProps) {
        var dropinConfig = makeDropinConfig(token, nextProps.amount, null, nextProps.settings);
        this.dropin.create(dropinConfig, this.onDropinCreated);
    }

    initBraintree(props) {
        // Don't run this server-side
        if (typeof window === 'undefined') {
            console.log('prevented initBraintree')
            return;
        }
        if (this.state.btInitializing) {
            props.logDevError("Warning: braintree already initialized or still initializing");
            return;
        }
        this.setState({ btInitializing: true, btLoaded: false });
        setTimeout(this.onBrainteeLoadTimeout, braintreeLoadTimeoutDuration);
        const clientTokenEndpoint = props.braintreeClientTokenEndpoint;

        fetch(clientTokenEndpoint,
            {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                credentials: 'same-origin',
                body: JSON.stringify(props.placeOrderDetails)
            })
            .then((response) => {
                if (response.ok)
                    return response.json();
                throw new Error(`${response.status}: ${response.statusText}`)
            })
            .then((clientTokenResult) => {
                if (clientTokenResult.Success)
                    this.onClientTokenReceived(clientTokenResult.Token, props, this.dropin);
                else
                    props.onClientTokenFailed(clientTokenResult.canRetry, clientTokenResult.ErrorMessage);
            }).catch((error) => {
                props.onClientTokenFailed(false, error.message);
            });
    }

    onClickSuccess(e) {
        e && e.preventDefault && e.preventDefault();
        const dropinInstance = this.state.dropinInstance;
        if (!dropinInstance && dropinInstance.requestPaymentMethod) {
            this.props.onSuccessNoDropin(
                translateResourceString("/Checkout/PaymentInformation/Braintree/SomethingWentWrong"),
                `dropin is undefined or wrong: ${dropinInstance}`);
        }
        dropinInstance.requestPaymentMethod((getPaymentErr, payload) => {
            if (getPaymentErr) {
                this.props.onSuccessNoDropin(translateResourceString("/Checkout/PaymentInformation/Braintree/ErrorGettingPaymentMethod") + ": " +
                    getPaymentErr,
                    getPaymentErr);
                return;
            }
            this.setState({ nonce: payload.nonce });
            this.props.onSuccesfulRequest(payload, this.props.braintreePaymentId);
        });
    }

    render() {
        let content;
        const disabled = (this.state.btInitializing || !this.state.paymentMethodAvailable);
        if (this.state.nonce && this.state.nonce.length > 0) {
            content = (<div>
                {translateResourceString("/Checkout/PaymentInformation/Braintree/AlreadyAuthorizedStartOver")}&nbsp;&nbsp;
                <button className='btn btn-r-red' onClick={(e) => this.onStartOver(e)}>
                    {translateResourceString("/Checkout/PaymentInformation/Braintree/StartOverButton")}
                </button>
            </div>);
        } else {
            content = (
                <div>
                    <label htmlFor='bt-ponumber'>
                        {translateResourceString("/Checkout/PaymentInformation/Purchase/PONumber")}
                    </label>
                    <input
                        className="form-control"
                        name='bt-ponumber'
                        id='bt-ponumber'
                        type='text'
                        placeholder={translateResourceString("/Checkout/PaymentInformation/Purchase/PONumberPlaceholderOptional")}
                        onChange={e => this.props.onPurchaseOrderChange(e)}
                        value={this.props.poNumber}
                    />
                    <div id="braintreeHolder" className={`pt-3`} />
                    {this.state.btInitializing &&
                        <div style={{ paddingBottom: 10 }}><span className="fas fa-spinner fa-spin" /></div>
                    }
                    <button
                        className={`btn btn-r-red ${disabled ? "disabled" : ""}`}
                        onClick={e => this.onClickSuccess(e)}
                        onKeyPress={e => e.key === "Enter" && this.onClickSuccess()}
                        style={{ marginRight: 10 }}
                        tabIndex={0}
                    >
                        {translateResourceString("/Checkout/PaymentInformation/Braintree/UseMethodButton")}
                    </button>
                    <button
                        className={`btn btn-r-red-outline`}
                        onClick={(e) => this.onPaymentCancel(e)}
                        tabIndex={0}
                    >
                        {translateResourceString("/Checkout/PaymentInformation/Braintree/CancelButton")}
                    </button>
                </div>
            )
        }
        return (
            <div className="braintreePayment" hidden={!this.state.isOpen} style={{ margin: 17 }}>
                {content}
            </div>
        );
    }
}

const mapBraintreePaymentStateToProps = (state, ownProps) => {
    const settings = state.constants.braintreeSettings;
    const entities = state.entities;
    const addresses = entities.Addresses;
    const summary = entities.Summary;

    return {
        settings,
        amount: summary.TotalRemaining,
        billingAddress: addresses.filter(a => a.Id === entities.SelectedBillingAddressId)[0],
        braintreeClientTokenEndpoint: state.constants.navigation.braintreeClientTokenEndpoint,
        braintreePaymentId: settings.braintreePaymentId,
        placeOrderDetails: mapStateToPlaceOrderDetails(state),
        BraintreePaymentNonce: state.entities.PaymentRecord.BraintreePaymentNonce,
        ...ownProps
    }
};

const mapBraintreePaymentDispatchToProps = (dispatch, ownProps) => {
    return {
        onBraintreeLoadTimeout: () => dispatch(notifyError(translateResourceString("/Checkout/PaymentInformation/Braintree/FailedToLoad"),
            `Braintree form load timed out after ${braintreeLoadTimeoutDuration}ms`)),
        onClientTokenFailed: (canRetry, serverMessage) => {
            const retryMessage = (canRetry) ? translateResourceString("/Checkout/PaymentInformation/Braintree/ErrorSecondPartReload") : translateResourceString("/Checkout/PaymentInformation/Braintree/ErrorSecondPartContactService");
            const text = `${translateResourceString("/Checkout/PaymentInformation/Braintree/ErrorFirstPart")} ${retryMessage}`;
            dispatch(notifyError(text, `message from server: "${serverMessage}"`));
        },
        onCreateDropinError: (text, diagnosticText) => dispatch(notifyError(text, diagnosticText)),
        logDevError: (text) => dispatch(logError(text)),
        onSuccesfulRequest: (payload, braintreePaymentId) => {
            ownProps.onPaymentIsValid();
            dispatch(selectPaymentOption(braintreePaymentId, "Braintree"));
            dispatch(updateBraintreePaymentData(payload));
        },
        onSuccessNoDropin: (text, diagnosticText) => dispatch(notifyError(text, diagnosticText)),
        onStartOver: () => ownProps.onPaymentCancel(),
        onPurchaseOrderChange: (e) => (e && e.target && dispatch(updatePurchaseOrderNumber(e.target.value)))
    };
};

const BraintreePaymentComponentWrapper = connect(mapBraintreePaymentStateToProps, mapBraintreePaymentDispatchToProps)(BraintreePaymentComponent)

export default BraintreePaymentComponentWrapper
