import React from "react";
import { CheckoutSection, hiddenAnchorStyle, OptionListItemRadio } from "../../components";
import { translateResourceString } from '../../../util/translationUtility';
import { Fade } from "../../componentAnimations";
import UseMyAccountComponent from "../ShippingMethod/UseMyAccountComponent"

const CheckoutShipmentPresentation = (
  {
    shippingOptions,
    selectedId,
    canChange,
    onClickChange,
    isCollapsed,
    onSelected,
    isPendingRefresh
  }) => {
  const selectedOption = shippingOptions.find(opt => opt.Id === selectedId)

   const UseMyAccountComponent = selectedOption ? selectedOption.UseMyAccountComponent : null;
    return (
    <CheckoutSection label='Shipping Method' hidden={false}>
      {isPendingRefresh
        ? <span className="fa fa-spinner fa-spin"/>
        : (
                    <React.Fragment>
                        {shippingOptions.length > 0 &&
                            <div className="d-flex justify-content-between" onClick={(e) => onClickChange(e)} style={{ cursor: 'pointer' }}>
                                <span>
                                    {!!selectedOption ? selectedOption.NameWithPrice : "Select Shipping Method"}
                                </span>
                                    {canChange &&
                                (isCollapsed ? (<a href=""><i className="fas fa-caret-down"></i></a>)
                                    : (<a href=""><i className="fas fa-caret-up"></i></a>))
                            }
                            </div>}
                        {shippingOptions.length === 0 &&
                            <div>{translateResourceString("/Checkout/ShippingMethod/NoShippingMethodsAvailable")}</div>}

                        {shippingOptions.length > 0 && (
                            <Fade inProp={!isCollapsed}>
                                <div className="mt-1"><hr />
                                    {shippingOptions.map(sm => (
                                        <OptionListItemRadio
                                            id={sm.Id}
                                            key={sm.Id}
                                            isSelected={(sm.Id === selectedId)}
                                            onClick={onSelected}
                                            label={sm.NameWithPrice}
                                            style={hiddenAnchorStyle}
                                        />
                                    ))}
                                </div>
                                {UseMyAccountComponent && <div>{<UseMyAccountComponent />}</div>}
                            </Fade>)}
          </React.Fragment>
        )}
    </CheckoutSection>
  );
};

export default CheckoutShipmentPresentation
